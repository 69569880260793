import NextLink from 'components/foundation/non-sitecore/NextLink';
import { NavigationLink } from '../global-navigation/shop/navigation-props';
import { UtilityNavigationProps } from './utility-navigation-props';
import { useCartSummaryData } from 'components/cart/cart-hooks';

const CartNavigation = (props: UtilityNavigationProps): JSX.Element => {
  const cartItem = props.fields?.data?.datasource?.children?.results?.find(
    (i) => i.name === 'Cart'
  ) as NavigationLink;

  const cartSummary = useCartSummaryData();
  const totalCount: number | undefined =
    cartSummary?.totalProductCount && cartSummary.totalProductCount;

  return (
    <NextLink
      field={cartItem?.link?.jsonValue}
      className="group leading-none flex flex-row flex-wrap hover:text-accent-cool-light rounded text-avidgreen relative active:text-accent-cool-lighter"
      hideText={true}
    >
      <>
        <span
          className={`w-6 h-6 inline-block
            bg-[url('https://cdn.avid.com/avidcom/images/shared/icons/cart-icon.svg')]
            bg-[length:18px_18px] bg-no-repeat bg-center`}
        ></span>
        <span className="group-hover:underline font-avidwalsheimbold group-active:underline lg:not-sr-only sr-only break-normal font-bold leading-normal lg:ml-1">
          {cartItem ? cartItem.link?.jsonValue?.value?.text : 'Cart'}
        </span>
        <span
          className={`flex justify-center items-center font-avidwalsheimbold absolute left-3 -top-2 lg:-top-12px bg-tertiary text-white px-1 rounded-2xl min-w-30px font-bold text-center align-middle text-14 border-2 border-neutral-darkest tracking-light leading-15px ${
            totalCount && totalCount > 0 ? 'block' : 'hidden'
          }`}
        >
          {totalCount}
        </span>
      </>
    </NextLink>
  );
};

export default CartNavigation;
